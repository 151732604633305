// .home {
//   display: flex;

//   .homeContainer {
//     flex: 6;

//     .widgets,
//     .charts {
//       display: flex;
//       padding: 20px;
//       gap: 20px;
//     }

//     .charts {
//       padding: 5px 20px;
//     }

//     .listContainer {
//       -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
//       box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
//       padding: 20px;
//       margin: 20px;

//       .listTitle {
//         font-weight: 500;
//         color: gray;
//         margin-bottom: 15px;
//       }
//     }
//   }
// }



.home {
  display: flex;

  .homeContainer {
    flex: 6;

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .home {
    display: block;
    // display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-x: hidden;
  }
}
  // @media screen and (min-width: 769px) and (max-width: 1024px) {
  //   .home {
  //     display: inline-flex;
  //   }
// }

// @media (min-width: 900px) and (max-width: 1200px) {
//   /* Add your CSS styles here */
//   body {
//     max-width: 100%;
//     background-color: lightblue;
//   }
//   /* Other styles... */
// }

// @media (min-width:768px) and (max-width: 1024px) {
//   body{
//     padding-right:0px !important
//   }
//   .home {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     overflow-x: hidden;
//   }
//   .loc-grid .css-bzgugk-MuiGrid-root>div{
//     max-width: 100%;
//   }
//   .home .homeContainer{
//     max-width: 768px;
//   }
//   .-ml-5-loc{
//     margin-left: -1.25rem !important
//   }
// }